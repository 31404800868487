@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.container {
  display: flex;
  flex-direction: column;
  gap: rem(16);
}

.submitBtn {
  border-radius: 40px;
  background: var(--alpha-walletSubmitBtn);
  height: 48px;
  padding: 8px 60px;
  gap: 10px;
  text-align: center;
  width: 100%;
  color: var(--alpha-btn-text);
  text-align: center;
  font-family: var(--font-body);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.14px;
  margin-top: rem(24);

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.6;
  }
}


.asset_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &_amount_box {
    display: flex;
    flex-direction: column;
    gap: rem(8);
    flex: 1;
    align-items: center;
  }

  &_amount {
    font-size: rem(20);
    font-weight: 600;
    color: #ffffff;
    white-space: nowrap;
  }
  &_ic_arrow {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  &_amount_title {
    font-size: rem(12);
    font-weight: 500;
    color: #CECECE;
    white-space: nowrap;
  }
}

.horizontal_space {
  padding-left: rem(16);
  padding-right: rem(16);
}

.note {
  margin-top: rem(12) !important;
  font-size: rem(14);
  font-weight: 500;
  line-height: 150%;
  text-align: center;
  color: #CECECE;
  white-space: break-spaces;
}

.error {
    font-size: rem(14);
    font-weight: 500;
    line-height: 150%;
    text-align: center;
    color: #FF0000;
    white-space: break-spaces;
}