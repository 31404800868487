@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.wrapper {
  background: var(--alpha-bg);
  height: 100vh;
  padding: 0 20px;
  text-align: center;
  display: grid;
  place-items: center;

  * {
    color: var(--alpha-text);
  }

  .imgBlock {
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
  }

  .title {
    margin-bottom: 8px;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  .text {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    max-width: rem(600px);

    strong {
      font-weight: 700;
    }
  }

  .content {
    margin-bottom: 48px;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
  }

  .btn {
    display: flex;
    height: 40px;
    margin: 24px auto;
    padding: 8px 24px;
    align-items: center;
    justify-content: center;
    border-radius: 1000px;
    background: var(--alpha-btn-bg);

    color: var(--alpha-btn-text);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  .action_wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .create_btn {
    background-color: #fff;
    padding: 11px 0px;
    color: black;
    font-size: 14px;
    border-radius: 100px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.14px;
    text-align: center;
    white-space: nowrap;
  }

  .import_btn {
    background: rgba(255, 255, 255, 0.1);
    padding: 11px 0px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    border-radius: 100px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.14px;
    white-space: nowrap;
  }
}

.jackpotDeposit_web {
  font-family: $font-alp;
  text-align: center;
  display: grid;
  place-items: center;
  height: 100vh;
  background: var(--alpha-bg);

  .logo_wrapper {
    width: rem(172px);
    margin-left: auto;
    margin-right: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  p {
    color: var(--alpha-text);
    font-size: 20px;
    font-weight: 600;
    line-height: 160%;
    /* 32px */
    margin-bottom: rem(16);
  }

  .link {
    border-radius: 100px;
    border: 1px solid rgba(248, 240, 172, 0.2);
    background: rgba(248, 240, 172, 0.1);
    font-size: 14px;
    font-weight: 500;
    line-height: 160%;
    text-decoration-line: underline;
    cursor: pointer;
    margin-bottom: 32px;
    padding: 12px 20px;
    color: #f8f0ac;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .wqr {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: var(--alpha-text);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 00;
      line-height: 160%;
      /* 25.6px */
      margin-bottom: 24px;
    }
  }

  .qr {
    box-shadow: 0px 0px 24px 0px #0000001f;
    background: #ffffff;
    padding: rem(8);
    width: fit-content;
    margin-bottom: 32px;
  }

  .installText {
    color: var(--alpha-text);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    line-height: 1;

    span {
      color: rgba(white, 0.8);
    }
    b {
      font-weight: 600;
    }
  }

  .listPositive {
    margin-top: 24px;
    color: #cecece;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 8px;
      justify-content: center;
    }

    li {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 16px;
      height: 16px;
      display: inline-block;
      margin-right: 4px;
    }
  }
}

.loadingWrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.permissionText {
  color: #000;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 16.8px */
  margin-top: 12px;
}

.welcome_web_left {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: rem(40px) rem(32px);

  &_heading {
    color: #fff;
    text-align: center;
    font-size: rem(20px);
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    margin-top: rem(32px);
    margin-bottom: rem(16px);
  }
}

.notes {
  text-align: center;
  max-width: rem(614px);
  margin-left: auto;
  margin-right: auto;
  color: #cecece;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.welcome_pc {
  display: flex;
  gap: 48px;
}

// .welcome_web_right {
//   background: rgba(#ffffff, 0.1);
//   border-radius: 20px;
//   padding: rem(40px) rem(32px);
//   display: flex;
//   align-items: center;
//   text-align: center;

//   &_thumb {
//     display: flex;
//     justify-content: center;
//     margin-bottom: rem(32px);
//   }

//   &_content {
//     text-align: center;

//     &_heading {
//       color: #FFF;
//       text-align: center;
//       font-size: 20px;
//       font-style: normal;
//       font-weight: 700;
//       line-height: 160%; /* 32px */
//       margin-bottom: rem(16px);
//     }

//     &_sub {
//       color: #ECECED;
//       font-size: 16px;
//       font-style: normal;
//       font-weight: 600;
//       line-height: 160%; /* 25.6px */
//       margin-bottom: rem(16px);
//     }

//     ul {
//       color: #CECECE;
//       font-size: 14px;
//       font-style: normal;
//       font-weight: 400;
//       line-height: 140%; /* 19.6px */
//       list-style: none;

//       li {
//         display: flex;
//         justify-content: center;
//         margin-bottom: 6px;

//         span {
//           display: flex;
//           align-items: center;
//           width: fit-content;
//           justify-content: center;
//           gap: 5px;

//           &:before {
//             content: '';
//             border: 2px solid #CECECE;
//             border-radius: 50%;
//           }
//         }
//       }
//     }
//   }
// }

@media (max-width: 1440px) {
  .welcome_pc {
    padding-left: rem(32px);
    padding-right: rem(32px);
    gap: 32px;
  }
}
