@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.notEnoughStorage {
  color: #fff;
  .title {
    margin-bottom: 16px;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }

  .text {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }

  .tips {
    margin: 8px 0 24px 0;
    padding: 8px 12px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;

    ul {
      margin-left: 20px;
      color: rgba(255, 255, 255, 0.8);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      list-style-type: disc;
    }
  }

  .note {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-align: center;
  }
}

.btnClose {
  position: absolute !important;
  top: 12px !important;
  right: 12px !important;
}
